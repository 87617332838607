import React from 'react';
import SectionHeader from '../sectionHeader/SectionHeader';

const Features = ({ props }) => {

    const { data, header, subHeader, text, styleConfig, icon, sectionId } = props;
    let delay = 0;
    let newSectionId = "";
    if (!sectionId) {
        newSectionId = ""
    } else {
        newSectionId = sectionId
    }

    return (
        <section
            id={newSectionId}
            style={{
                textAlign: styleConfig.contentCenter ? "center" : undefined, // Conditional text alignment
                ...(styleConfig.backgroundImage.use && { // Conditionally apply background styles
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0.0) 100%), url(${styleConfig.backgroundImage.url})`,
                    backgroundPosition: 'right',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                })
            }}
            className="text-gray-600 body-font"
        >
            <div className="container px-5 py-24 mx-auto">
                <SectionHeader content={header} subContent={subHeader} icon={icon} />
                {text && <div className="mb-10 grid lg:grid-cols-3 gap-8">
                    <div className={`backdrop-blur-md flex h-full p-8 flex-row items-center bg-white/[0.5] ${styleConfig.hoverEffect ? " hover:shadow-md transition-shadow " : ""} `}>
                        <div className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-maincolor text-white flex-shrink-0">
                            <svg className="w-7 h-7" viewBox="0 -8 72 72" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><defs><style></style></defs><title>handshake</title><path className="cls-1" d="M64,12.78v17s-3.63.71-4.38.81-3.08.85-4.78-.78C52.22,27.25,42.93,18,42.93,18a3.54,3.54,0,0,0-4.18-.21c-2.36,1.24-5.87,3.07-7.33,3.78a3.37,3.37,0,0,1-5.06-2.64,3.44,3.44,0,0,1,2.1-3c3.33-2,10.36-6,13.29-7.52,1.78-1,3.06-1,5.51,1C50.27,12,53,14.27,53,14.27a2.75,2.75,0,0,0,2.26.43C58.63,14,64,12.78,64,12.78ZM27,41.5a3,3,0,0,0-3.55-4.09,3.07,3.07,0,0,0-.64-3,3.13,3.13,0,0,0-3-.75,3.07,3.07,0,0,0-.65-3,3.38,3.38,0,0,0-4.72.13c-1.38,1.32-2.27,3.72-1,5.14s2.64.55,3.72.3c-.3,1.07-1.2,2.07-.09,3.47s2.64.55,3.72.3c-.3,1.07-1.16,2.16-.1,3.46s2.84.61,4,.25c-.45,1.15-1.41,2.39-.18,3.79s4.08.75,5.47-.58a3.32,3.32,0,0,0,.3-4.68A3.18,3.18,0,0,0,27,41.5Zm25.35-8.82L41.62,22a3.53,3.53,0,0,0-3.77-.68c-1.5.66-3.43,1.56-4.89,2.24a8.15,8.15,0,0,1-3.29,1.1,5.59,5.59,0,0,1-3-10.34C29,12.73,34.09,10,34.09,10a6.46,6.46,0,0,0-5-2C25.67,8,18.51,12.7,18.51,12.7a5.61,5.61,0,0,1-4.93.13L8,10.89v19.4s1.59.46,3,1a6.33,6.33,0,0,1,1.56-2.47,6.17,6.17,0,0,1,8.48-.06,5.4,5.4,0,0,1,1.34,2.37,5.49,5.49,0,0,1,2.29,1.4A5.4,5.4,0,0,1,26,34.94a5.47,5.47,0,0,1,3.71,4,5.38,5.38,0,0,1,2.39,1.43,5.65,5.65,0,0,1,1.48,4.89,0,0,0,0,1,0,0s.8.9,1.29,1.39a2.46,2.46,0,0,0,3.48-3.48s2,2.48,4.28,1c2-1.4,1.69-3.06.74-4a3.19,3.19,0,0,0,4.77.13,2.45,2.45,0,0,0,.13-3.3s1.33,1.81,4,.12c1.89-1.6,1-3.43,0-4.39Z"></path></g></svg>
                        </div>
                        <span className='text-gray-900 text-lg title-font font-medium'>{text}</span>
                    </div>
                </div>}
                <div className="flex flex-wrap -m-4">
                    {/* iterate */}
                    {data?.map((item, key) => {
                        delay += 100;
                        return (
                            <div data-aos="fade-up" data-aos-delay={delay} className="p-4 md:w-1/2 lg:w-1/3" key={Math.random()}>
                                <div className={`backdrop-blur-md flex h-full p-8 flex-col bg-white/[0.5] ${styleConfig.hoverEffect ? " hover:shadow-md transition-shadow " : ""} `}>
                                    <div
                                        style={{
                                            justifyContent: styleConfig.contentCenter ? "center" : undefined,
                                        }}
                                        className="flex items-center mb-3">
                                        <div
                                            className="w-10 h-10 mr-3 inline-flex items-center justify-center rounded-full bg-maincolor text-white flex-shrink-0">
                                            <span
                                                dangerouslySetInnerHTML={{ __html: item.icon }}
                                            />
                                        </div>
                                        <h2 className="text-gray-900 text-lg title-font font-medium">{item.header}</h2>
                                    </div>
                                    <div className="flex-grow">
                                        <p className="leading-relaxed text-base text-black">{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    {/* iterate */}

                </div>
            </div>
        </section>
    );
}

export default Features;
