import React, { useState, useEffect } from "react";
import { Config } from "../../Config";
import { useFilter } from "../../context/filterContext";

const EcommerceFilterProducts = ({ onFilter, arrayLength }) => {

  const { filter, setFilter, pagination, setPagination } = useFilter();

  const [carBrand, setCarBrand] = useState(filter.carBrand || "");
  const [carModel, setCarModel] = useState(filter.carModel || "");
  const [milleage, setMilleage] = useState(filter.milleage || "");
  const [startingPrice, setStartingPrice] = useState(filter.startingPrice || "");
  const [carModels, setCarModels] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Fetch car models data when the component mounts
  useEffect(() => {
    const fetchCarModels = async () => {
      try {
        const response = await fetch(`${Config.headlessConfig.baseURL}/acp/api/content/items/carFilter`, {
          method: "GET",
          headers: {
            "api-key": Config.headlessConfig.API_KEY,
          },
        });
        const data = await response.json();
        if (data.length > 0 && data[0].filters) {
          setCarModels(data[0].filters); // Set the filters (car brands and models)
        }
      } catch (error) {
        console.error("Error fetching car models:", error);
      }
    };

    fetchCarModels();
  }, []);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Set filter visibility based on screen width
  useEffect(() => {
    if (windowWidth >= 1024) {
      setIsFilterVisible(true); // Show filters on larger screens (e.g., desktops)
    } else {
      setIsFilterVisible(false); // Hide filters on smaller screens (e.g., mobile)
    }
  }, [windowWidth]);

  const handleSearch = () => {
    setPagination({
      currentPage: 1,
      offset: 0,
      limit: 15
    })
    onFilter({ carBrand, carModel, milleage, startingPrice });
  };

  const handleClearFilter = () => {
    setFilter({});
    setCarBrand("");
    setCarModel("");
    setMilleage("");
    setStartingPrice("");

  }

  return (
    <div className="border border-slate-100 flex flex-col w-full mx-auto overflow-hidden my-4 p-5 bg-white xl:mx-32 bg-slate-50 shadow-md">
      <div className="flex justify-between items-center">
        <h1 className="text-xl md:text-3xl font-semibold text-neutral-800">Liczba aukcji: {arrayLength}</h1>
        <button
          onClick={() => setIsFilterVisible(!isFilterVisible)}
          className="bg-white border-2 border-maincolor text-maincolor px-4 py-2 text-sm font-medium hover:shadow-md transition-all"
        >
          {isFilterVisible ? "Ukryj filtry - " : "Pokaż filtry + "}
        </button>
      </div>

      {/* Collapsible filter section with animation */}
      <div
        className={`transition-all duration-500 ease-in-out overflow-hidden ${isFilterVisible ? "mt-6 max-h-[1000px] opacity-100" : "max-h-0 opacity-0"}`}
      >
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 mt-4">
          <div className="flex flex-col">
            <label htmlFor="carBrand" className="text-neutral-700 font-normal mb-1 text-sm">Marka:</label>
            <select
              id="carBrand"
              value={carBrand}
              onChange={(e) => setCarBrand(e.target.value)}
              className="p-3 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">- Wybierz Markę -</option>
              {Object.keys(carModels).map((brand) => (
                <option key={Math.random()} value={brand}>{brand}</option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="carModel" className="text-neutral-700 font-normal mb-1 text-sm">Model:</label>
            <select
              id="carModel"
              value={carModel}
              onChange={(e) => setCarModel(e.target.value)}
              className="p-3 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">- Wybierz Model -</option>
              {carBrand && carModels[carBrand]?.map((model) => {
                return (<option key={Math.random()} value={model}>{model}</option>)
              })}
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="milleage" className="text-neutral-700 font-normal mb-1 text-sm">Przebieg do:</label>
            <input
              type="number"
              id="milleage"
              value={milleage}
              onChange={(e) => setMilleage(e.target.value)}
              className="p-3 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="startingPrice" className="text-neutral-700 font-normal mb-1 text-sm">Cena do:</label>
            <input
              type="number"
              id="startingPrice"
              value={startingPrice}
              onChange={(e) => setStartingPrice(e.target.value)}
              className="p-3 border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Search button */}
        <div className="mt-6 flex justify-center lg:justify-end gap-4">

          <button
            onClick={handleClearFilter}
            className="bg-white text-maincolor px-4 py-2 text-sm font-medium border-2 border-maincolor hover:shadow-md transition-all"
          >
            Wyczyść filtry
          </button>

          <button
            onClick={handleSearch}
            className="bg-maincolor text-white px-4 py-2 text-sm font-medium hover:bg-maincolor hover:shadow-md transition-all"
          >
            Filtruj
          </button>
        </div>
      </div>
    </div>
  );
};

export default EcommerceFilterProducts;
