import React from 'react'
import bgImage from '../../assets/images/IMG_8372.jpeg';
const Content = () => {
    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-md px-4 md:px-8">
                <h1 className="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6">Nasza przewaga konkurencyjna</h1>

                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8">
                    SmartCars to oficjalna firma pośrednicząca w licytowaniu bezwypadkowych samochodów krajowych z dostępem do wszystkich domów, serwisów i grup aukcyjnych w całej Polsce.
                    Nasza siedziba znajduje się w Krakowie. Obsługujemy klientów z całej Polski, zapewniając pełne wsparcie podczas licytacji i zakupu samochodu.
                </p>

                <h2 className="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">O nas</h2>

                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8">
                    Osobiście uczestniczymy w aukcjach, sprawdzamy, czy samochód jest zgodny z opisem we wcześniejszym raporcie domu aukcyjnego lub serwisu, a także przesyłamy wysokiej jakości zdjęcia pojazdu.
                    Staramy się uprościć proces zakupu wymarzonego samochodu, dlatego towarzyszymy klientom na każdym etapie zakupu i transportu auta.
                </p>

                <ul className="mb-6 list-inside list-disc text-gray-500 sm:text-lg md:mb-8">
                    <li>Bezpośredni udział w aukcjach samochodowych</li>
                    <li>Sprawdzanie zgodności pojazdu z raportem</li>
                    <li>Przesyłanie wysokiej jakości zdjęć pojazdu</li>
                </ul>

                <blockquote className="mb-6 border-l-4 pl-4 italic text-gray-500 sm:text-lg md:mb-8 md:pl-6">“W przeciwieństwie do aukcji amerykańskich, krajowe samochody z polskich salonów od pierwszych właścicieli są wystawiane jako bezwypadkowe, bezszkodowe i w pierwszym lakierze.”</blockquote>

                <div className="relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8">
                    <img src={bgImage} width={600} height={350} loading="lazy" alt="Zdjęcie samochodu" className="w-full object-cover " />
                </div>

                <h2 className="mb-2 text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4">Funkcje</h2>

                <p className="text-gray-500 sm:text-lg">
                    Kolejnym atutem jest fakt, że samochód można mieć pod domem już po 7 dniach od wygrania licytacji, po opłaceniu faktury i odbiorze pojazdu. Zaufaj nam, a my udowodnimy naszą pasję i zaangażowanie!
                </p>

            </div>
        </div>
    )
}

export default Content