import React from 'react'
import bgImage from '../../assets/images/bg6.png';

const Hero = ({props}) => {
    
    return (
        <>
            <section className="text-zinc-400 bg-zinc-900 body-font"
                style={{
                    backgroundImage: `linear-gradient(rgba(24, 24, 27, 1) 0%, rgba(0, 0, 0, 0.7) 100%), url(${bgImage})`,
                    backgroundPosition: 'center left',
                    backgroundSize: 'cover'
                }}
            >
                <div className={`container mx-auto flex px-5 py-24 items-center justify-center flex-col min-h-[calc(100vh_-_72px_-_63px)] md:min-h-[calc(100vh_-_77px_-_63px)] lg:min-h-[calc(100vh_-_102px_-_63px)]`}>
                    <div className="md:w-8/12 relative">
                        <div className="block text-center text-right mb-4 mr-0 lg:mr-0">
                            <span  className='md:inline bg-maincolor/[0.5] text-white font-bold uppercase text-xs md:text-2xl  lg:text-3xl p-2'>Aukcje pojazdów na żywo</span>
                        </div>
                        <div className="block text-center">
                            <h1 
                           
                            className="title-font text-4xl md:text-5xl lg:text-7xl mb-4 font-bold text-white uppercase mx-auto">Wylicytujmy razem twój wymarzony samochód!</h1>
                        </div>
                        <div className="text-center">
                            <span className='text-maincolor lg:text-xl uppercase font-bold'>Bezwypadkowe • Salon PL • Pierwszy właściciel • Niski przebieg</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero