import React, { useEffect, useState } from 'react';
import Collections from '../components/collections/Collections';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import HeroSmall from '../components/hero/HeroSmall';
import { Config } from '../Config';

const Fleet = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchContentItems = async () => {
            try {
                const url = `${Config.headlessConfig.baseURL}/acp/api/content/items/autaNaSprzedaz?sort[_created]=-1`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'api-key': Config.headlessConfig.API_KEY,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                setData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchContentItems();
    }, []);

    const forSale = data.filter(item => !item.isSold);
    const sold = data.filter(item => item.isSold);

    return (
        <>
            <Header showContactBelt={true} />
            <HeroSmall props={{
                sizeOfHero: "50vh"
            }} />
            {!loading ? <Collections props={{
                header: "Auta na sprzedaż",
                data: forSale.map(item => ({
                    id: item._id,
                    car: item.carFullName,
                    image: `${Config.headlessConfig.baseURL}${Config.headlessConfig.baseURLMedia}${item.coverPhoto.path}`,
                    price: item.startingPrice,
                    carDetail: {
                        year: item.yearOfProduction,
                        milleage: item.milleage,
                        hp: item.horsePower,
                        engine: item.fuelType,
                        engineCapacity: item.engineCapacity,
                    }
                }))
            }} /> : ""}
            {!loading ? <Collections props={{
                header: "Auta sprzedane",
                data: sold.map(item => ({
                    id: item._id,
                    car: item.carFullName,
                    image: `${Config.headlessConfig.baseURL}${Config.headlessConfig.baseURLMedia}${item.coverPhoto.path}`,
                    price: item.startingPrice,
                    carDetail: {
                        year: item.yearOfProduction,
                        milleage: item.milleage,
                        hp: item.horsePower,
                        engine: item.fuelType,
                        engineCapacity: item.engineCapacity,
                    }
                }))
            }} /> : "" }
            <Footer />
        </>
    );
};

export default Fleet;
