import { createContext, useContext, useState } from "react";

const FilterContext = createContext();

export const useFilter = () => useContext(FilterContext);

export const FilterProvider = ({ children }) => {

    const [filter, setFilter] = useState({});
    const [pagination, setPagination] = useState({
        currentPage: 1,
        offset: 0,
        limit: 15
    });
    const [cacheResult, setCacheResult] = useState({});

    const value = { filter, setFilter, cacheResult, setCacheResult, pagination, setPagination }

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;

}