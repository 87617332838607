import React, { useState, useEffect } from 'react';
import Header from '../components/header/Header';
import EcommerceHero from '../components/ecommerceHero/EcommerceHero';
import Footer from '../components/footer/Footer';
import BreadCrumbs from '../components/breadCrumbs/BreadCrumbs';
import { useParams } from 'react-router-dom';
import EcommerceSlider from '../components/ecommerceSlider/EcommerceSlider';
import { Config } from '../Config';

const AuctionContent = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchAuctionData = async () => {
            try {
                const response = await fetch(`${Config.headlessConfig.baseURL}/acp/api/content/item/carAuctions/${id}`, {
                    method: 'GET',
                    headers: {
                        'api-key': Config.headlessConfig.API_KEY
                    }
                });
                const data = await response.json();

                // Format gallery images as arrays of URLs
                const contentCarGallery = data.contentCarGallery.map(item =>
                    `${Config.headlessConfig.baseURL}${Config.headlessConfig.baseURLMedia}${item.path}`
                );

                const expertDescriptionGallery = data.expertDescriptionGallery.map(item =>
                    `https://smart-cars.pl/acp/storage/uploads${item.path}`
                );

                // Set the product state with the API data and formatted galleries
                setProduct({ ...data, contentCarGallery, expertDescriptionGallery });
            } catch (error) {
                console.error("Error fetching auction data:", error);
            }
        };

        fetchAuctionData();
    }, [id]);


    return (
        <>
            <Header showContactBelt={false}/>
            {product ? <EcommerceHero
                key={Math.random()}
                id={product._id}
                title={product.carFullName}
                img={`https://smart-cars.pl/acp/storage/uploads${product.coverPhoto.path}`}
                auctionDate={product.auctionDate}
                yearOfProduction={product.yearOfProduction}
                mileage={product.milleage}
                horsePower={product.horsePower}
                engineCapacity={product.engineCapacity}
                price={product.startingPrice}
                images={product.contentCarGallery}
                carBrand={product.carBrand}
                gearbox={product.gearbox}
            /> : ""}

            {product ? <EcommerceSlider
                images={product.expertDescriptionGallery}
            /> : ""}
            <Footer />
        </>
    );
};

export default AuctionContent;
