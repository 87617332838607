import React from 'react'
import smartCarsLogo from '../../assets/images/smart-cars-logo.svg'
import { Link } from 'react-router-dom'
import { Config } from '../../Config'
const Cta = () => {
    return (
        <div className="mx-auto my-10 w-screen max-w-screen-xl px-4">
            <div className="to flex items-center justify-between bg-gradient-to-r from-black to-zinc-600 px-8 text-white shadow-lg">
                <div className="my-10 lg:my-8 lg:w-1/2">
                    <h1 className="text-2xl font-bold">Brak samochodu</h1>
                    <p className="mt-4 text-lg">Nie znalazłeś samochodu, który Cię interesuje w naszej ofercie? Skontaktuj się z nami – indywidualnie poszukamy idealnego pojazdu dla Ciebie i zajmiemy się wszystkimi formalnościami.</p>
                    <div className="pt-6">
                        <button className="bg-maincolor text-white px-4 py-4 text-sm font-medium hover:bg-maincolor hover:shadow-md transition-all">
                            <Link to={'tel:' + Config.contact.phone}>
                                Zadzwoń {Config.contact.phone}
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="hidden lg:block">
                    <img className="h-24" src={smartCarsLogo} alt="" />
                </div>
            </div>
        </div>

    )
}

export default Cta