import React from 'react'

const SectionHeader = ({ content, subContent, icon }) => {
    return (
        <div className="flex flex-wrap flex-col mb-10">
            <h2 className="flex items-center sm:text-3xl text-2xl font-medium title-font text-white px-4 py-2 uppercase bg-zinc-900 mx-auto mb-4">
                {icon && <span dangerouslySetInnerHTML={{ __html: icon }} className=''></span>}
                {content}
            </h2>
            <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
                {subContent}
            </p>
        </div>

    )
}

export default SectionHeader