import React from 'react'

const Collections = ({ props }) => {

    const { header, data } = props;

    return (
        <div className="bg-white py-6 sm:py-8 lg:py-12">
            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div className="mb-6 flex items-end justify-between gap-4 border-b-2 border-zinc-900">
                    <h2 className="flex items-center text-xl font-medium title-font text-white px-4 py-2 uppercase bg-zinc-900">
                        {header}
                    </h2>
                </div>
                <div className="grid gap-x-4 gap-y-6 sm:grid-cols-2 md:gap-x-6 lg:grid-cols-3 xl:grid-cols-4">

                    {data.map((item) => {
                        return (
                        <div className='shadow-md hover:shadow-lg transition-all border border-slate-200'>
                            <div className="group mb-2 block overflow-hidden bg-gray-100 lg:mb-3">
                                <img src={item.image} loading="lazy" alt={item.image} className="h-full w-full object-contain object-cover transition duration-200" />
                            </div>

                            <div className="flex flex-col px-4 py-2 pt-0">
                                <span className="text-lg font-bold text-gray-800 transition duration-100 lg:text-xl">{item.car}</span>
                                <div className="flex flex-row xl:flex-wrap items-center gap-1 lg:gap-2 my-1 text-gray-500 ">
                                    <span className="text-gray-500 text-xs xl:text-sm text-nowrap">{item.carDetail.year}r. •</span>
                                    <span className="text-gray-500 text-xs xl:text-sm text-nowrap">{item.carDetail.milleage} km •</span>
                                    <span className="text-gray-500 text-xs xl:text-sm text-nowrap">{item.carDetail.engine} •</span>
                                    <span className="text-gray-500 text-xs xl:text-sm text-nowrap">{item.carDetail.hp} KM •</span>
                                    <span className="text-gray-500 text-xs xl:text-sm text-nowrap">{item.carDetail.engineCapacity}L.</span>
                                </div>
                                <span className="text-3xl font-bold text-maincolor transition duration-100 lg:text-3xl">{item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || ""} zł</span>
                            </div>
                        </div>
                        )
                    })}


                </div>
            </div>
        </div>
    )
}

export default Collections