import React, { useEffect, useState, useCallback } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import EcommerceCardProduct from '../components/ecommerceCardProduct/EcommerceCardProduct';
import Container from '../components/container/Container';
import EcommerceFilterProducts from '../components/ecommerceFilterProducts/EcommerceFilterProducts';
import { Config } from '../Config';
import Cta from '../components/cta/Cta';
import ProductSkeleton from '../components/skeleton/ProductSkeleton';
import { useFilter } from '../context/filterContext';

const Auction = () => {

    const { filter, setFilter, pagination, setPagination } = useFilter();

    const ITEMS_PER_PAGE = 15;

    const [products, setProducts] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);

    // Load last visited page from localStorage
    useEffect(() => {
        const savedPage = pagination.currentPage;
        if (savedPage && savedPage > 0) {
            setPagination((prev) => ({
                ...prev,
                currentPage: savedPage,
                offset: (savedPage - 1) * ITEMS_PER_PAGE,
            }));
        }
    }, []);

    // Fetch data with filter and pagination
    const fetchData = useCallback(async () => {
        setLoading(true);

        try {
            const filterQuery = buildFilterQuery(filter);
            const response = await fetch(
                `${Config.headlessConfig.baseURL}/acp/api/content/items/carAuctions?limit=${pagination.limit}&skip=${pagination.offset}&sort[_created]=-1&${filterQuery}`,
                {
                    method: 'GET',
                    headers: { 'api-key': Config.headlessConfig.API_KEY },
                }
            );

            if (!response.ok) throw new Error(`API error: ${response.status}`);
            const data = await response.json();

            setProducts(data?.data || []);
            setTotalItems(data?.meta?.total || 0);
        } catch (error) {
            console.error('Error fetching car auctions:', error);
            setProducts([]);
            setTotalItems(0);
        } finally {
            setLoading(false);
        }
    }, [filter, pagination]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Build filter query for the API
    const buildFilterQuery = (filterParams) => {
        return Object.entries(filterParams)
            .map(([key, value]) => {
                if (!value) return '';
                if (['milleage', 'startingPrice'].includes(key)) {
                    return `filter[${key}][$lte]=${encodeURIComponent(value.trim())}`;
                }
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return Object.entries(value)
                        .map(([op, val]) => `filter[${key}][${op}]=${encodeURIComponent(val.trim())}`)
                        .join('&');
                }
                return `filter[${key}]=${encodeURIComponent(value.trim())}`;
            })
            .filter(Boolean)
            .join('&');
    };

    // Handle filter changes
    const handleFilter = (newFilters) => setFilter(newFilters);

    // Handle pagination changes
    const handlePageChange = (page) => {
        setPagination({
            currentPage: page,
            offset: (page - 1) * ITEMS_PER_PAGE,
            limit: ITEMS_PER_PAGE,
        });
        window.scrollTo(0, 0);
    };

    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

    // Pagination button
    const renderPaginationButton = (page, isDisabled, label) => (
        <button
            onClick={() => handlePageChange(page)}
            disabled={isDisabled}
            className={`border border-neutral-100 shadow-lg px-3 py-2 text-sm font-medium ${isDisabled
                ? 'bg-neutral-200 text-neutral-400 cursor-not-allowed'
                : 'bg-white text-neutral-700 hover:bg-neutral-100'
                }`}
        >
            {label}
        </button>
    );

    return (
        <>
            <Header showContactBelt={false} />
            <Container>
                <EcommerceFilterProducts arrayLength={totalItems} onFilter={handleFilter} />

                {!loading ? (
                    products.length > 0 ? (
                        products.map((product, index) => (
                            <EcommerceCardProduct
                                key={product._id || index}
                                id={product._id}
                                title={product.carFullName}
                                img={
                                    product?.coverPhoto?.path
                                        ? `${Config.headlessConfig.baseURL}${Config.headlessConfig.baseURLMedia}${product.coverPhoto.path}`
                                        : 'https://via.placeholder.com/150'
                                }
                                auctionDate={product.auctionDate || 'Brak'}
                                expertDescription={product.expertDescription || 'Brak opisu'}
                                carIsVerified={product.carIsVerified || false}
                                yearOfProduction={product.yearOfProduction || 'N/A'}
                                mileage={product.milleage || 0}
                                horsePower={product.horsePower || 0}
                                engineCapacity={product.engineCapacity || 'N/A'}
                                price={product.startingPrice || '0'}
                                carBrand={product.carBrand || 'Brak'}
                                carModel={product.carModel || 'Brak'}
                                fuelType={product.fuelType || 'Brak'}
                            />
                        ))
                    ) : (
                        <Cta />
                    )
                ) : (
                    <div className="my-4 p-5 bg-white xl:mx-32 shadow-md w-full">
                        {Array.from({ length: 3 }, (_, i) => <ProductSkeleton key={i} />)}
                    </div>
                )}

                {/* Pagination */}
                <div className="flex justify-center items-center mt-4 space-x-2 py-12 w-full">
                    {renderPaginationButton(1, pagination.currentPage === 1, '↞')}
                    {renderPaginationButton(pagination.currentPage - 1, pagination.currentPage === 1, '←')}
                    {Array.from(
                        { length: Math.min(5, totalPages - Math.max(0, pagination.currentPage - 3)) },
                        (_, index) => {
                            const page = Math.max(1, pagination.currentPage - 2) + index;
                            return (
                                <button
                                    key={page}
                                    onClick={() => handlePageChange(page)}
                                    className={`border border-neutral-100 shadow-lg px-4 py-2 text-sm font-medium ${page === pagination.currentPage ? 'bg-maincolor text-white' : 'bg-white text-neutral-700'
                                        }`}
                                >
                                    {page}
                                </button>
                            );
                        }
                    )}
                    {renderPaginationButton(pagination.currentPage + 1, pagination.currentPage === totalPages, '→')}
                    {renderPaginationButton(totalPages, pagination.currentPage === totalPages, '↠')}
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default Auction;
